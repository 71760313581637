/* initialize fields with floating label */
$('.floatingLabel').jvFloat();

/* inizialize the inout mask */
$('#birthDate').inputmask('dd/mm/yyyy');

/* check if the form has been wrongly filled and eventually scroll down */
if($('#isFormError').val() == 'true') {

	$('html, body').animate({
        scrollTop: $("form").offset().top
    }, 1500);
}

/* last page scroll down */
if($('#successPage').val() == 'true') {

	$('html, body').animate({
        scrollTop: $("#confirmSection").offset().top
    }, 1500);
}

/* only number tyoed in textarea (telephone number) */
function isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}